<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <h1 style="text-transform: uppercase">{{ pageTitle }}</h1>
        <div class="d-flex align-items-center mb-6">
          <span class="text-muted font-weight-bold font-size-lg flex-grow-1"
            >You can add or Remove Taxes as you please.
          </span>
          <div class="symbol symbol-50">
            <span class="symbol-label bg-light-light">
              <img
                src="/media/svg/icons/Home/Timer.svg"
                class="h-50 align-self-center"
                alt=""
              />
            </span>
          </div>
        </div>
        <!--end::Info-->
        <v-app>
          <v-container>
            <v-row style="margin-top: 25px;">
              <v-col cols="12" class="d-flex justify-end text-center">
                <v-btn
                  class="primary custom_button"
                  :disabled="false"
                  large
                  height="50"
                  style="margin-right: 15px;"
                  @click="dialog = true"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Add new Tax
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="tax_types"
                  :items-per-page="30"
                  sort-by="percent"
                  class="table1"
                  loading-text="Loading taxes... Please wait"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="removeItem(item)"
                      style="font-size: 18px;"
                      color="red"
                    >
                      mdi-close-circle
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <template>
            <v-row justify="center">
              <v-dialog v-model="dialog" persistent max-width="600px">
                <v-card>
                  <v-card-title>
                    <span class="headline">Add Tax Type</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="newTaxType.name"
                              :rules="[rules.required]"
                              label="Tax name*"
                              hint="This field is required"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="newTaxType.description"
                              label="Description"
                              hint="This field is not required"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-currency-field
                              v-model="newTaxType.percent"
                              :rules="[rules.required]"
                              label="Percent*"
                              :default="0.0"
                              :decimalLength="2"
                              :min="0"
                              :max="100"
                              prefix="%"
                              hint="This field is required"
                              required
                            ></v-currency-field>
                          </v-col>
                        </v-row>
                        <div v-if="formInvalid" class="fillAllFieldsMessage">
                          {{ formInvalidMessage }}
                        </div>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      :loading="loading"
                      color="blue darken-1"
                      text
                      @click="dialog = false"
                    >
                      Close
                    </v-btn>
                    <v-btn
                      :loading="loading"
                      color="blue darken-1"
                      text
                      @click="saveNewTaxType()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
        </v-app>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import TaxType from "../../../stub/taxtype";

export default {
  name: "settings",
  data: function() {
    return {
      pageTitle: "Tax Types",
      headers: [
        { text: "Tax Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Percent", value: "percent" },
        { text: "Options", value: "actions", sortable: false }
      ],
      rules: {
        required: value => !!value || "Required."
      },
      formInvalidMessage: "*Please complete all input fields",
      formInvalid: false,
      newTaxType: { ...TaxType },
      dialogSaveButton: null,
      dialog: false,
      loading: false,
      tax_types: []
    };
  },
  created() {
    this.loadingvariable = true;
    ApiService.query("taxtypes")
      .then(({ data }) => {
        this.tax_types = data.tax_types;
        this.loadingvariable = false;
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          this.loadingvariable = false;
          this.$store
            .dispatch(LOGOUT)
            .then(() => this.$router.push({ name: "login" }));
        }
      });
  },
  methods: {
    saveNewTaxType() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        //this.dialog = true;

        ApiService.post("taxtypes/store", {
          newTaxType: this.newTaxType
        })
          .then(({ data }) => {
            this.tax_types.push(data.tax_types);
            this.newTaxType = { ...TaxType };
            this.loading = false;
            this.dialog = false;
          })
          .catch(() => {
            this.loading = false;
            this.dialog = false;
          });
      }
    },
    removeItem(item) {
      Swal.fire({
        title: "Delete Tax Type",
        text: "Would you like to delete this Tax?",
        icon: "warning",
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: "Yes"
      }).then(result => {
        if (result.value) {
          ApiService.delete("/taxtypes/delete/" + item.id)
            .then(() => {
              window.location.reload();
            })
            .catch(({ response }) => {
              if (response.status === 401) {
                if (response.status === 401) {
                  this.$store
                    .dispatch(LOGOUT)
                    .then(() => this.$router.push({ name: "login" }));
                }
              }
              this.loadingvariable = false;
            });
        }
      });
    },
    getHeaders(headingText) {
      return [
        {
          text: "Dynamic heading no. " + headingText,
          align: "left",
          sortable: false,
          value: "name"
        }
      ];
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>

<style scoped></style>
